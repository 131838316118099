import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { TextField, IconButton, Menu, MenuItem, Select } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Createbtn from '../createbtn/create'
import ArchiveShortIssuesTable from './archiveshortissues'
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import NativeSelect from '@mui/material/NativeSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareCheck, faTrashCan } from '@fortawesome/free-regular-svg-icons'
import { faBullhorn, faPaperclip, faBinoculars } from '@fortawesome/free-solid-svg-icons'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CommentIcon from '@mui/icons-material/Comment';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import LandscapeIcon from '@mui/icons-material/Landscape';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import MergeIcon from '@mui/icons-material/Merge';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import 'react-quill/dist/quill.snow.css';
import { styled } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import '../teamTodoTable/style.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardHeader } from '@mui/material';
import Box from '@mui/material/Box';
const ShortIssues = () => {

  // createbtn modal

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [issueData, setissueData] = React.useState([]);
  const [isLoading, setisloading] = React.useState();
  const [length, setLength] = React.useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}todos/get`);
        console.log(response);
        // let shorttermissues=response.data.todos;
        // // console.log(shorttermissues).
        let shortTermIssues = response.data.todos.filter(issue => issue.interval === 'Short Term');
        setissueData(shortTermIssues);
        setLength(shortTermIssues.length);
        console.log("This is set issue data", shortTermIssues);
        console.log("this is issue data", issueData);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  const columns = [
    // { id: '_id', label: 'ID' },
    { id: 'name', label: 'Title' },
    { id: 'interval', label: 'Interval' },
    { id: 'description', label: "Description" },
    { id: 'owner', label: "Owner" },
  ];

  // pagination below

  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  //quil

  const [value, setValue] = React.useState('');

  var toolbarOptions = [
    ['bold', 'italic'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'direction': 'rtl' }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],
    [{ 'font': [] }],
    [{ 'align': [] }],

    ['clean']
  ];
  const module = {
    toolbar: toolbarOptions,
  };

  // file upload
  const [file, setFile] = React.useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile); // Save the selected file

    if (selectedFile) {
      setInputValues({
        ...inputValues,
        attachment: selectedFile.name, // Update attachment in inputValues
      });
    }
  };

  const handleCancel = () => {
    setFile(null); // Remove the file
  };

  // apply the style in the uploaded file
  const HoverableDiv = styled('div')({
    display: 'flex',
    justifyContent: "space-between",
    marginTop: '5px',
    '&:hover': {
      backgroundColor: '#f5f5f5', // Change the background color on hover
    },
  });

  //comment button
  const [isButtonsVisible, setButtonsVisible] = React.useState(false);

  const handleTextFieldClick = () => {
    setButtonsVisible(true);
    setInputValues({
      ...inputValues,
      comment: '', // Set the initial value if needed
    });
  };

  const handleCancelButtonClick = () => {
    setButtonsVisible(false);
  };

  //checkbox
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  //inputvaluestoconsole

  const [inputValues, setInputValues] = React.useState({
    team: '',
    priority: '',
    title: '',
    description: '',
    who: '',
    attachment: '',
    comment: '',
  });

  const handleInputChange = (e, selectName) => {
    const { name, value } = e.target;

    if (selectName) {
      // Handle the Select separately
      setInputValues({
        ...inputValues,
        [selectName]: value,
      });
    } else {
      setInputValues({
        ...inputValues,
        [name]: value,
      });
    }
  };

  const handleSave = () => {
    console.log(inputValues);
  };
  // edit card state
  const [isEditCard, setEditcardvisible] = React.useState(false);
  const handleCardShow = () => {
    setEditcardvisible(true);
  };
  const handleCardClose = () => {
    setEditcardvisible(false);
  };

  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          // Makes the content take up the full viewport height
        }}
      >
        {isLoading ? (
          <p>
            Loading...
          </p>

        ) : length > 0 ? (
          <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
            <TableContainer component={Paper}>
              <Typography variant='h4' style={{ paddingLeft: "30px", paddingTop: "30px", marginRight: "40px" }}> Short-Term 1212
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.slice(0, 1).map((column) => (
                      <TableCell style={{ color: '#9C9C9C' }} align='left' key={column.id}>{column.label}</TableCell>
                    ))}
                    {/*Comment, Attachments, # */}
                    <TableCell style={{ color: '#9C9C9C', width: "40px" }} align='right'>Attachment</TableCell>
                    <TableCell style={{ color: '#9C9C9C', width: "40px" }} align='right'>Comments</TableCell>
                    <TableCell style={{ color: '#9C9C9C', width: "75px" }} align='right'>#</TableCell>
                    {/* ends */}
                    {/* {columns.slice(1, 2).map((column) => (
                    <TableCell align='right' key={column.id}>{column.label}</TableCell>
                  ))} */}
                    {/* {columns.slice(2, 3).map((column) => (
                    <TableCell align='right' key={column.id}>{column.label}</TableCell>
                  ))} */}
                    {columns.slice(3, 4).map((column) => (
                      <TableCell style={{ color: '#9C9C9C', width: "40px" }} align='right' key={column.id}>{column.label}</TableCell>
                    ))}
                    <TableCell style={{ color: '#9C9C9C', width: "40px" }} align='right'>More</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(issueData) && issueData.map((row) => (
                    <TableRow key={row._id}>
                      {columns.slice(0, 1).map((column) => (
                        <TableCell
                          style={{ display: 'flex' }}
                          align='left'
                          key={column.id}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >
                          <Checkbox
                            checked={checked[row._id]}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                            className="p-0"
                            color="success"
                          />
                          <h6 className="mt-2 ms-2">{row[column.id]}</h6>
                          {isHovered && (
                            <EditIcon
                              style={{ marginLeft: '5px', verticalAlign: 'middle' }}
                              onClick={handleCardShow}
                            />
                          )}

                          {/* <span data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions" className="edit-icon" style={{ left: "180px" }}><EditIcon /></span> */}
                        </TableCell>
                      ))}

                      <TableCell align='right' ><FontAwesomeIcon icon={faPaperclip} style={{ fontSize: '20px', color: '#9c9c9c' }} />&nbsp; &nbsp; 0</TableCell>
                      <TableCell align='right' ><CommentIcon style={{ fontSize: '20px', color: '#9c9c9c' }} />&nbsp; &nbsp; 0</TableCell>
                      <TableCell align='right' className="text-end justify-content-end- align-items-end" >
                        <FormControl>
                          <NativeSelect
                            defaultValue={1}
                            inputProps={{
                              name: 'priority',
                              id: 'uncontrolled-native',
                            }}

                          >
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                          </NativeSelect>
                        </FormControl>
                      </TableCell>

                      {/* {columns.slice(1, 2).map((column) => (
                      <TableCell align='right' key={column.id}>{row[column.id]}</TableCell>
                    ))}
                    {columns.slice(2, 3).map((column) => (
                      <TableCell align='right' key={column.id}>{row[column.id]}</TableCell>
                    ))} */}
                      {columns.slice(3, 4).map((column) => (
                        <TableCell align='right' key={column.id} style={{ display: "flex", justifyContent: "end", paddingBottom: "9px" }}>
                          <Avatar>OP</Avatar>
                        </TableCell>
                      ))}
                      {/* {columns.slice(0,1).map((column) => (
                      <TableCell key={column.id}>{row[column.id]}</TableCell>
                    ))} */}
                      <TableCell align='right'>
                        <MoreHorizIcon id="basic-button"
                          aria-controls={open ? 'basic-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          onClick={handleClick}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <div className="d-flex justify-content-between">

                <button onClick={openModal} disabled={isModalOpen} className="btn p-0 btn-white">
                  <h6 className="mt-3 ms-2" style={{ color: '#1993c6', fontFamily: 'Poppins, sans-serif' }} >+ Add Issue</h6>
                  <Createbtn isOpen={isModalOpen} onClose={closeModal} />
                </button>
                <TablePagination
                  component="div"
                  count={100}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{
                    ".MuiTablePagination-displayedRows": {
                      marginTop: "12px",
                    },
                    ".MuiTablePagination-spacer": {
                      paddingTop: 1,
                    },
                    ".MuiTablePagination-selectLabel": {
                      marginBottom: '8px',
                    },
                    ".MuiSelect-select": {
                      paddingTop: 0,
                    },
                  }}
                />
              </div>

            </TableContainer>
            {isEditCard && (
              <div>
                <Card style={{ minWidth: 400, marginLeft: 10, height: 550, overflowY: "auto", paddingBottom: "12px" }}>
                  <CardHeader style={{ marginBottom: "12px" }} title={
                    <Typography gutterBottom variant="h5" component="h2" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                      <RadioButtonUncheckedIcon style={{ fontSize: '28px', marginRight: '10px', color: '#707070' }} onClick={handleSave} />
                      <h5 className="mt-1" style={{ fontFamily: 'Poppins, sans-serif', fontWeight: '600' }} >Edit Issue</h5>
                      <Typography style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <MoreHorizIcon onClick={handleClick} style={{ color: '#9A9A9A', marginLeft: '90px' }} />
                        <Avatar className="" sx={{ width: 30, height: 30, backgroundColor: '#4E545B' }}>S</Avatar>
                        <CloseIcon onClick={handleCardClose} style={{ cursor: "pointer" }} />
                      </Typography>

                    </Typography>
                  } />
                  <Typography component="hr" />
                  <CardContent>
                    <div className="d-flex flex-column w-100">
                      <FormControl>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                          Team*
                        </InputLabel>
                        <NativeSelect
                          defaultValue={'Leadership Team'}
                          inputProps={{
                            name: 'team',
                            id: 'uncontrolled-native',
                          }}
                          onChange={(e) => handleInputChange(e, 'team')}

                        >
                          <option value={'Leadership Team'}>Leadership Team</option>
                          <option value={'Team 1'}>Team 1</option>
                          <option value={'Team 2'}>Team 2</option>
                        </NativeSelect>
                      </FormControl>

                      <FormControl className="my-4">
                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                          Priority
                        </InputLabel>
                        <NativeSelect
                          defaultValue={1}
                          inputProps={{
                            name: 'priority',
                            id: 'uncontrolled-native',
                          }}
                          onChange={(e) => handleInputChange(e, 'priority')}

                        >
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                        </NativeSelect>
                      </FormControl>
                    </div>
                    <InputLabel>Title</InputLabel>
                    <TextField id="outlined-basic" size="small" variant="standard" fullWidth name="title" value={inputValues.title} onChange={handleInputChange} />

                    <InputLabel className="mt-4">Description</InputLabel>
                    <ReactQuill modules={module} style={{ marginTop: "8px" }} theme="snow" value={value} onChange={setValue} />
                    <TextField className="mt-3" id="standard-basic" label="Who" variant="standard" fullWidth name="who" value={inputValues.who} onChange={handleInputChange} />
                    <hr className="p-0 my-4" />

                    <h5 className="ms-3" style={{ fontFamily: 'Poppins, sans-serif', fontWeight: '600' }}>Attachments</h5>

                    <div className="py-2 mt-3 rounded rounded-3" style={{ backgroundColor: '#F5F5F5' }} >
                      <p className="text-center pt-2" style={{ fontFamily: 'Poppins, sans-serif', color: '#707070', fontSize: '14px' }}>Drag and drop files to attach, or <label htmlFor="file-upload" className="fw-bold" style={{ color: '#2596BE' }} >browse</label></p>
                    </div>

                    <input
                      type="file"
                      id="file-upload"
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                    />

                    {file && (
                      <HoverableDiv>
                        <Typography>{file.name}</Typography>
                        <CloseIcon onClick={handleCancel} style={{ cursor: 'pointer', color: "black", backgroundColor: "none" }} />
                      </HoverableDiv>
                    )}

                    <hr className="p-0 my-4" />

                    <h5 className="ms-3" style={{ fontFamily: 'Poppins, sans-serif', fontWeight: '600' }}>Comments</h5>

                    <div className="d-flex">
                      <Avatar className="ms-3 mt-3" sx={{ width: 30, height: 30, backgroundColor: '#757575' }}>S</Avatar>
                      <TextField className="mt-3 ms-3" placeholder="Add a comment.." id="outlined-basic" size="small" variant="outlined" fullWidth
                        onClick={handleTextFieldClick}
                        onChange={(e) => handleInputChange(e, 'comment')}
                        value={inputValues.comment}
                      />
                    </div>

                    {isButtonsVisible && (
                      <div className="d-flex ms-5 mt-3">
                        <button style={{ backgroundColor: '#2596BE' }} className="btn text-white mx-3">Comment</button>
                        <button onClick={handleCancelButtonClick} className="btn btn-outline-secondary">Cancel</button>
                      </div>
                    )}
                  </CardContent>
                </Card>
              </div>
            )}
          </Box>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              // Makes the content take up the full viewport height
            }}
          >

            {/* Icon */}
            <PriorityHighIcon style={{ fontSize: 100, marginBottom: '16px', color: '#0000008a' }} />

            {/* Heading */}
            <Typography variant="p" align="center" gutterBottom sx={{ color: '#0000008a', fontSize: '20px', fontWeight: '500', fontFamily: 'Poppins,sans-serif' }}>
              Your team hasn't added any Issues yet.
            </Typography>

            {/* Paragraph */}
            <Typography variant="body1" align="center" paragraph sx={{ color: '#0000008a', fontSize: '14px', fontWeight: '400', fontFamily: 'Nunito Sans,sans-serif' }}>
              Improve communication and transparency to matters that need attention with Issues.
            </Typography>

            {/* Button */}
            <Button variant="contained" sx={{ width: '250px', height: '40px', textTransform: 'capitalize', marginTop: '16px', bgcolor: '#1993c6', '&:hover': { bgcolor: '#156f96', }, }} onClick={openModal} disabled={isModalOpen}>
              <Createbtn isOpen={isModalOpen} onClose={closeModal} />
              Add Issue
            </Button>

            {/* Text Link */}
            <Typography variant="body2" align="center" sx={{
              marginTop: '24px', color: '#1993c6', fontSize: '14px', fontWeight: '400', fontFamily: 'Nunito Sans,sans-serif', '& a': {
                color: '#1993c6',
                textDecoration: 'none',
                '&:hover': {
                  color: '#156f96',
                },
              },
            }}>
              <Link href="#">Learn more about Issues</Link>
            </Typography>

          </div>
        )}
      </div>

      {/* menu */}
       <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }
        }
      >
        <MenuItem onClick={handleClose}><ArrowUpwardIcon style={{ color: '#989898', fontSize: '22px', marginRight: '12px' }} /> Top of List</MenuItem>
        <MenuItem onClick={handleClose}><ArrowDownwardIcon style={{ color: '#989898', fontSize: '22px', marginRight: '12px' }} /> Bottom of List</MenuItem> <hr className="m-0 p-0" />
        <MenuItem onClick={handleClose}><ArchiveOutlinedIcon style={{ color: '#989898', fontSize: '22px', marginRight: '12px' }} /> Archive</MenuItem>
        <MenuItem onClick={openModal}><LandscapeIcon style={{ color: '#989898', fontSize: '22px', marginRight: '12px' }} /> Create Rock</MenuItem>
        <MenuItem onClick={openModal} > <CheckBoxOutlinedIcon style={{ color: '#989898', fontSize: '22px', marginRight: '10px' }} /> Create To-Do</MenuItem>
        <MenuItem onClick={openModal} > <GppMaybeOutlinedIcon style={{ color: '#989898', fontSize: '22px', marginRight: '10px' }} /> Create Issue</MenuItem>
        <MenuItem onClick={openModal} > <FontAwesomeIcon icon={faBullhorn} style={{ color: '#989898', marginRight: '10px' }} /> Create Headline</MenuItem>
        <MenuItem onClick={openModal} > <MergeIcon style={{ color: '#989898', marginRight: '10px' }} /> Merge into Issue</MenuItem>
        <MenuItem onClick={openModal} > <AccessAlarmIcon icon={faBullhorn} style={{ color: '#989898', marginRight: '10px' }} /> Short Term</MenuItem>
        <MenuItem onClick={openModal} > <FontAwesomeIcon icon={faBinoculars} style={{ color: '#989898', marginRight: '10px' }} /> Long Term</MenuItem>
        <hr className="m-0 p-0" />
        <Createbtn isOpen={isModalOpen} onClose={closeModal} />
        <MenuItem onClick={handleClose} ><FontAwesomeIcon icon={faTrashCan} style={{ color: '#989898', marginRight: '19px' }} />Delete</MenuItem>
      </Menu>

      {/* <div className="offcanvas offcanvas-end" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel" style={{ width: "440px" }}>

        <div className="offcanvas-header">

          <div className="offcanvas-title d-flex">
            <RadioButtonUncheckedIcon style={{ fontSize: '28px', marginRight: '10px', color: '#707070' }} onClick={handleSave} />
            <h5 className="mt-1" style={{ fontFamily: 'Poppins, sans-serif', fontWeight: '600' }} >Edit Issue</h5>
          </div>
          <MoreHorizIcon onClick={handleClick} style={{ color: '#9A9A9A', marginLeft: '90px' }} />
          <Avatar className="" sx={{ width: 30, height: 30, backgroundColor: '#4E545B' }}>S</Avatar>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>

        <div className="offcanvas-body">
          <div className="d-flex flex-column w-50">
            <FormControl>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Team*
              </InputLabel>
              <NativeSelect
                defaultValue={'Leadership Team'}
                inputProps={{
                  name: 'team',
                  id: 'uncontrolled-native',
                }}
                onChange={(e) => handleInputChange(e, 'team')}

              >
                <option value={'Leadership Team'}>Leadership Team</option>
                <option value={'Team 1'}>Team 1</option>
                <option value={'Team 2'}>Team 2</option>
              </NativeSelect>
            </FormControl>

            <FormControl className="my-4">
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Priority
              </InputLabel>
              <NativeSelect
                defaultValue={1}
                inputProps={{
                  name: 'priority',
                  id: 'uncontrolled-native',
                }}
                onChange={(e) => handleInputChange(e, 'priority')}

              >
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
              </NativeSelect>
            </FormControl>
          </div>
          <TextField className="mb-3" id="standard-basic" label="Title" variant="standard" fullWidth name="title" value={inputValues.title} onChange={handleInputChange} />

          <ReactQuill modules={module} style={{ marginTop: "8px" }} theme="snow" value={value} onChange={setValue} />

          <TextField className="mt-3" id="standard-basic" label="Who" variant="standard" fullWidth name="who" value={inputValues.who} onChange={handleInputChange} />

          <div className="d-flex mt-2">
            <h6 className="ms-3 mt-3" style={{ fontFamily: 'Poppins, sans-serif', fontWeight: '600', color: '#757575' }}>Attachments</h6>
            <label className="mt-3 ms-2" htmlFor="file-upload"><AddCircleOutlineIcon /></label>

          </div>

          <input
            type="file"
            id="file-upload"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />

          {file && (
            <HoverableDiv>
              <Typography>{file.name}</Typography>
              <CloseIcon onClick={handleCancel} style={{ cursor: 'pointer', color: "black", backgroundColor: "none" }} />
            </HoverableDiv>
          )}

          <hr className="p-0 my-4" />

          <h6 className="ms-3" style={{ fontFamily: 'Poppins, sans-serif', fontWeight: '600', color: '#757575' }}>Comments</h6>

          <div className="d-flex">
            <Avatar className="ms-3 mt-3" sx={{ width: 30, height: 30, backgroundColor: '#757575' }}>S</Avatar>
            <TextField className="mt-3 ms-3" placeholder="Add a comment.." id="outlined-basic" size="small" variant="outlined" fullWidth
              onClick={handleTextFieldClick}
              onChange={(e) => handleInputChange(e, 'comment')}
              value={inputValues.comment}
            />
          </div>

          <div className="d-flex ms-5 mt-3">
            <button style={{ backgroundColor: '#2596BE' }} className="btn text-white mx-3">Comment</button>
          </div>
          <div className="mt-4 mb-2">
            <small style={{ fontFamily: 'Poppins, sans-serif', color: '#9D9D9D' }}>Created by  on March 04, 2024</small>
          </div>


        </div> 

      </div> */}

    </>
  );
};

export default ShortIssues;