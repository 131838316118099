import React from 'react';
import Createbtn from '../../components/createbtn/create'
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { TextField, IconButton, Menu, MenuItem } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullhorn } from '@fortawesome/free-solid-svg-icons'

const CascadingMessages = () => {

// createbtn modal

const [isModalOpen, setIsModalOpen] = React.useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (

   <>
   <div className="card m-3 shadow">

     <div className="mt-3 ms-4"><h5 style={{ fontWeight: '600', fontSize: '22px',  letterSpacing: '0.5px'}} className="">Cascading Messages</h5></div>

    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '60vh',// Makes the content take up the full viewport height
      }}
    >

      <img className="m-0 mb-3 p-0" style={{ width: '100px' , marginBottom: '13px' }} src={`megaphone.png`}/>

      {/* Icon
      <div className="m-0 p-0" style={{ fontSize: 80, marginBottom: '13px', color:'#BDBDBD' }}><FontAwesomeIcon icon={faBullhorn}/></div> */}

      {/* Heading */}
      <Typography variant="p" align="center" gutterBottom sx={{color:'#757575', fontSize:'19px', fontWeight:'500', fontFamily:'Space Grotesk, sans-serif'}}>
        Your company hasn't added any Cascading Messages yet.
      </Typography>

      {/* Paragraph */}
      <Typography variant="body1" align="center" paragraph sx={{color:'#0000008a', fontSize:'14px', fontWeight:'500', fontFamily:'Space Grotesk, sans-serif'}}>
        Cascading Messages are a great way to share news across teams.
      </Typography>

      {/* Button */}
      <Button onClick={openModal} disabled={isModalOpen} variant="contained" sx={{width:'250px',height:'40px', textTransform:'capitalize' ,  marginTop:'14px', bgcolor:'#1993c6', '&:hover': {   bgcolor: '#156f96', },}}>
      <Createbtn isOpen={isModalOpen} onClose={closeModal} />
        Create Cascading Messages
      </Button>

      {/* Text Link */}
      <Typography variant="body2" align="center" sx={{marginTop: '23px', marginBottom: '20px', color:'#1993c6', fontSize:'14px', fontWeight:'400', fontFamily:'Nunito Sans,sans-serif', '& a': {
          color: '#1993c6',
          textDecoration: 'none',
          '&:hover': {
            color: '#156f96',
          },
        },}}>
        <Link href="#">Learn more about Cascading Messages.</Link>
      </Typography>


    </div>

    </div>
    </>
  );
};

export default CascadingMessages;