import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import PrintIcon from '@mui/icons-material/Print';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Grid } from '@mui/material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';

//modal
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  p: 3,
};

const appBarStyle = {
  background: 'white',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  borderRadius: '4px',
};

const buttonStyle = {
  color: '#1993c6',
  fontFamily: 'Nunito Sans, sans-serif',
  fontSize: '14px',
  fontWeight: '400',
};

const rightAlignStyle = {
  marginLeft: 'auto',
};

function MeetingAppBar() {
//modal
 const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //inputvaluestoconsole

     const [inputValues, setInputValues] = React.useState({
        team: '',
        meetingType: '',
      });

//const handleInputChange = (e) => {
//    const { name, value } = e.target;
//    setInputValues({
//        ...inputValues,
//        [name]: value,
//    });
//};

const handleInputChange = (e, nativeSelectName) => {
        const { name, value } = e.target;

        if (nativeSelectName) {
            // Handle NativeSelect separately
            setInputValues({
                ...inputValues,
                [nativeSelectName]: value,
            });
        } else {
            setInputValues({
                ...inputValues,
                [name]: value,
            });
        }
    };

const handleSave = () => {
  console.log(inputValues);
};

  return (
    <Grid sx={{ margin: '10px 5px 5px 5px', bgcolor: 'white' }}>
      <AppBar position="static" style={appBarStyle}>
        <Toolbar>
          <div style={{ flexGrow: 1 }}></div>
          <Button style={{ ...buttonStyle, ...rightAlignStyle }} endIcon={<PrintIcon />} sx={{marginRight:'30PX'}}>
            Print Agenda
          </Button>
          <Button onClick={handleOpen} style={{ ...buttonStyle, ...rightAlignStyle }} endIcon={<PlayArrowIcon />}>
            Start Meeting
          </Button>
        </Toolbar>
      </AppBar>

{/*start meeting modal open*/}
   <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h5 style={{fontFamily: 'Poppins,sans-serif'}} >Select the team</h5>
          <p className="mt-3" style={{fontFamily: 'Poppins,sans-serif', fontSize:'14px'}}>Which team is having this Meeting?</p>

    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel variant="standard" htmlFor="uncontrolled-native">
          Team &nbsp;<span className="text-danger">*</span>
        </InputLabel>
        <NativeSelect
          defaultValue={'Leadership Team'}
          inputProps={{
            name: 'team',
            id: 'uncontrolled-native',
          }}
          onChange={(e) => handleInputChange(e, 'team')}
        >
          <option value={'Leadership Team'}>Leadership Team</option>
          <option value={'Team 2'}>Team 2</option>
        </NativeSelect>
      </FormControl>
    </Box>

    <Box className="mt-3" sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel variant="standard" htmlFor="uncontrolled-native">
          Meeting Type &nbsp;<span className="text-danger">*</span>
        </InputLabel>
        <NativeSelect
          defaultValue={'Level 10 Meetings™'}
          inputProps={{
            name: 'meetingType',
            id: 'uncontrolled-native',
          }}
          onChange={(e) => handleInputChange(e, 'meetingType')}
        >
          <option value={'Level 10 Meetings™'}>Level 10 Meetings™</option>
          <option value={'Quarterly Sessions'}>Quarterly Sessions</option>
          <option value={'Annual Sessions'}>Annual Sessions</option>
          <option value={'Focus Day®'}>Focus Day®</option>
          <option value={'Vision Building® Day 1'}>Vision Building® Day 1</option>
          <option value={'Vision Building® Day 2 '}>Vision Building® Day 2 </option>
        </NativeSelect>
      </FormControl>
    </Box>

    <div className="row mt-3">
      <div className="col-12 col-lg-6 col-md-6"></div>

      <div className="col-12 col-lg-6 col-md-5 d-flex justify-content-end">
        <Button onClick={handleClose} className="bg-white text-black me-3" variant="contained">CANCEL</Button>
        <Button onClick={handleSave} style={{backgroundColor:'#2596BE'}} variant="contained">BEGIN</Button>
      </div>
    </div>

        </Box>
      </Modal>
    </div>
      {/* Your content goes here */}
    </Grid>
  );
}

export default MeetingAppBar;
