import { useState } from "react";
import { Routes, Route, Navigate, useNavigate,useLocation } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Issues from "./pages/Issues/Issues";
import CompanyADS from "./pages/CompanyADS/CompanyADS";
import Topbar from "./components/TopBar/TopBar";
import Scorecard from "./pages/Scorecard/Scorecard";
import Rocks from "./pages/Rocks/Rocks";
import Meetings from "./pages/Meetings/Meetings";
import Headlines from "./pages/Headlines/Headlines";
import VTO from "./pages/VTO/VTO";
import Chart from "./pages/Chart/Chart";
import Oneonone from "./pages/oneonone";
import Directory from "./pages/directory";
import Eostoolbox from "./pages/eostoolbox";
import NavBar from "./components/NavBar/NavBar";
import AllTodo from "./pages/ToDo/Todo";
import WeeklyTable from "./pages/Scorecard/weeklyTable";
import TrailingData from "./pages/Scorecard/trailingData";
import VerticalTabs from "./pages/setting";
import IssuesTable from "./components/issuesTable/issuesTable";
import Accountabilitychart from "./pages/accountabilitychart";

import { Data } from "./components/NavBar/Data";
import Login from "./pages/login/login";
import Signup from "./pages/signup/signup";
import CreateAccount from "./pages/signup/createAccount";
import SetUpCompany from "./pages/signup/setupcompany";
import Signup1 from "./pages/signup/signup1";
import Process from "./pages/process"
import ProvideFeedback from "./pages/providefeedback";
import InviteModal from "./pages/invitemodal";
import LearningSupport from "./pages/learningsupport";

function App() {
  const navigate = useNavigate();
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [selectedOption, setSelectedOption] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
    navigate('/home')

  }

  const handleOptionClick = (name) => {
    setSelectedOption(name);
  };

  const routeComponentNames = {
    '/home': 'ICTM',
    '/scorecard': 'Scorecard',
    '/data/weekly': 'WeeklyTable',
    '/rocks': 'Rocks',
    '/todo': 'To-Dos',
    '/issues': 'Issues',
    '/meetings': 'Meetings',
    '/headlines': 'Headlines',
    '/vision': 'Vision/Traction Organizer™',
    '/chart': 'Accountability Chart',
    '/1-on-1': '1-on-1',
    '/process': 'Process',
    '/directory': 'Directory',
    '/mastery': 'EOS Toolbox™',
    '/settings': 'Settings',
    '/1-on-1': '1-on-1',
    '/1-on-1': '1-on-1',
    
  };

  const location = useLocation();
  const currentRoute = location.pathname;
  const currentComponentName = routeComponentNames[currentRoute] || '';

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {isLoggedIn ? (
            <>
              <NavBar isSidebar={isSidebar} data={Data} onOptionClick={handleOptionClick} />
              <main className="content">
                <Topbar setIsSidebar={setIsSidebar} currentComponentName={currentComponentName} selectedOption={selectedOption} />
                <Routes>
                  <Route path="/home" element={<CompanyADS />} />
                  <Route path="/scorecard" element={<Scorecard />} />
                  <Route path="/data/weekly" element={<WeeklyTable />} />
                  <Route path="/data/trailing" element={<TrailingData />} />
                  <Route path="/rocks" element={<Rocks />} />
                  <Route path="/todo" element={<AllTodo />} />
                  <Route path="/issues" element={<Issues />} />
                  <Route path="/issues/short-term" element={<IssuesTable />} />
                  <Route path="/issues/long-term" element={<IssuesTable />} />
                  <Route path="/meetings" element={<Meetings />} />
                  <Route path="/headlines" element={<Headlines />} />
                  <Route path="/vision" element={<VTO />} />
                  <Route path="/chart" element={<Accountabilitychart />} />
                  <Route path="/1-on-1" element={<Oneonone />} />
                  <Route path="/process" element={<Process />} />
                  <Route path="/directory" element={<Directory />} />
                  <Route path="/mastery" element={<Eostoolbox />} />
                  <Route path="/settings" element={<VerticalTabs />} />
                  <Route path="/feedback" element={<ProvideFeedback />} />
                  <Route path="/invite" element={<InviteModal />} />
                  <Route path="/support" element={<LearningSupport />} />
                </Routes>
              </main>
            </>
          ) : (
            <Routes>
              <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
              <Route path="/login/sign-up" element={<Signup onLogin={handleLogin} />} />
              <Route path="/login/sign-up/create-account" element={<CreateAccount onLogin={handleLogin} />} />
              <Route path="/login/sign-up/setup-company" element={<SetUpCompany onLogin={handleLogin} />} />
              <Route path="/" element={<Navigate to="/login" />} /> {/* Default route to navigate to login */}
            </Routes>
          )}

        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;